<template>
    <div class="safety">
        <div class="safety-banner">
            <h1 class="title">{{ $t('safety.title') }}</h1>
        </div>
        <div class="main">
            <img :src="pic" class="h5-img" alt="">
            <img :src="pc_pic" class="pc-img" alt="">
        </div>
        <Tabbar :key="lang" :selectTab="2" />
    </div>
</template>

<script>
import Tabbar from "@/components/Tabbar/Tabbar.vue";
import { mapState, } from "vuex";
import vi_pic from "@/assets/safety-vi_VN.jpg"
import th_pic from "@/assets/safety-th_TH.jpg"
import br_pic from "@/assets/safety-pt_br.png"
import vi_pic_pc from "@/assets/safety-vi_VN-pc.png"
import th_pic_pc from "@/assets/safety-th_TH-pc.png"
import br_pic_pc from "@/assets/safety-pt_br-pc.png"
// const env_lang = this.$store.state.lang || process.env.VUE_APP_LANG
export default {
    data() {
        return {
            // pic: env_lang == 'vi_VN' ? vi_pic : (env_lang == 'th_TH' ? th_pic : br_pic),
            // pc_pic: env_lang == 'vi_VN' ? vi_pic_pc : (env_lang == 'th_TH' ? th_pic_pc : br_pic_pc),
        }
    },
    mounted() {
        console.log(this.pic)
        console.log(this.pc_pic)
    },
    components: {
        Tabbar,
    },
    computed: {
        ...mapState(['lang']),
        pic() {
            const env_lang = this.lang || process.env.VUE_APP_LANG;
            let img;
            switch (env_lang) {
                case 'vi_VN':
                    img = vi_pic;
                    break;
                case 'th_TH':
                    img = th_pic_pc;
                    break;
                case 'pt_br':
                    img = br_pic;
                    break;
            }
            return img;
        },
        pc_pic() {
            const env_lang = this.lang || process.env.VUE_APP_LANG;
            let img;
            switch (env_lang) {
                case 'vi_VN':
                    img = vi_pic_pc;
                    break;
                case 'th_TH':
                    img = th_pic;
                    break;
                case 'pt_br':
                    img = br_pic_pc;
                    break;
            }
            return img;
        }
    }
};
</script>

<style lang="less" scoped>
.safety {
    .safety-banner {
        width: 100%;
        height: 290px;
        background: url("../../assets/safety-top-bg.png") left -44px no-repeat;
        background-size: cover;
        overflow: hidden;
        .title {
            font-size: 18px;
            color: #333333;
            text-align: center;
            margin: 15px auto;
        }
    }
    .main {
        margin-top: -200px;
        min-height: calc(100vh - 49px - 90px);
        border-radius: 10px 10px 0 0;
        background: url(../../assets/login_bg.png) left top no-repeat #fff;
        background-size: 100%;
        padding: 20px 15px;
        .pc-img {
            display: none;
        }
        img {
            width: 100%;
            border-radius: 10px;
        }
    }
}
@media only screen and (min-width: 750px) {
    .safety {
        .safety-banner {
            width: 100%;
            height: 25.26vw;
            background: url("../../assets/safety-banner-pc.png") center top
                no-repeat;
            background-size: cover;
            .title {
                display: none;
            }
        }
        .main {
            margin-top: -28px;
            min-height: calc(100vh - 90px);
            border-radius: 0;
            background: none;
            padding-bottom: 30px;
            .h5-img {
                display: none;
            }
            img.pc-img {
                display: block;
            }
            img {
                width: 100%;
                margin: 0 auto;
                border-radius: 10px;
            }
        }
    }
}
</style>
